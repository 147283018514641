import { useState } from "react"

import { THomeAddress } from "src/data/homes/types/homeTypes"

export function useHomeGroupAddressForm(initialAddress?: THomeAddress) {
  const [address, setAddress] = useState(initialValues(initialAddress))

  function handleInputChange(addressUpdate: Partial<THomeAddress>) {
    setAddress({
      ...address,
      ...addressUpdate,
    })
  }

  function resetForm() {
    setAddress(initialValues(initialAddress))
  }

  return { address, handleInputChange, resetForm }
}

function initialValues(address?: THomeAddress) {
  return {
    street_name1: address?.street_name1 ?? "",
    street_name2: address?.street_name2 ?? "",
    post_code: address?.post_code ?? "",
    country: address?.country ?? "", // TODO WEB-414: Should be a list of country codes when BE requires it...
    city: address?.city ?? "",
    region: address?.region ?? "", // TODO WEB-414: Should be a list of province codes for specific countries when BE supports it
    notes: address?.notes ?? "",
  }
}
