import Geocode from "react-geocode"
import styled from "styled-components"

import { THomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { ILocation, THomeAddress } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { isEqual } from "src/utils/genericUtil"

import { HomeGroupAddressForm } from "./HomeGroupAddressForm"

export const HOME_GROUP_ADDRESS_FORM_ID = "home-group-address-form"

export function StepHomeGroupAddress({
  onSuccess,
  address,
  location,
}: {
  onSuccess: (address: THomeGroup["address"], location: ILocation) => void
  address?: THomeGroup["address"]
  location?: ILocation
}) {
  const formId = HOME_GROUP_ADDRESS_FORM_ID
  const { tPlaceholder } = useTranslate()

  async function handleSubmit(formData: THomeAddress) {
    let newLocation = location
    const dataChanged = !isEqual(formData, address)

    if (!newLocation || dataChanged) {
      const { street_name1, street_name2, country, city, post_code } = formData
      const addressString = `${street_name1} ${street_name2} ${city} ${post_code} ${country}`
      try {
        Geocode.setApiKey(import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY || "")
        const response = await Geocode.fromAddress(addressString)
        const { lat = 0, lng = 0 } =
          response.results?.[0]?.geometry?.location ?? {}
        newLocation = { latitude: lat, longitude: lng }
      } catch (e) {
        newLocation = { latitude: 0, longitude: 0 }
      }
    }

    onSuccess(
      {
        street_name1: formData.street_name1,
        post_code: formData.post_code,
        country: formData.country,
        city: formData.city,
        region: formData.region,
      },
      newLocation
    )
  }

  return (
    <div>
      <TitleBox>
        <MText variant="heading2">{tPlaceholder("Location address")}</MText>
      </TitleBox>

      <HomeGroupAddressForm
        onSubmit={handleSubmit}
        initialAddress={address}
        formId={formId}
      />
    </div>
  )
}

const TitleBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
  margin-bottom: ${spacing.XL};
`
